type TBulletPoint = {
	text: string
	variant?: 'default' | 'secondary'
}

export function BulletPoint({ text, variant = 'default' }: TBulletPoint) {
	return (
		<div className="inline-flex items-center gap-3">
			{variant === 'default' && (
				<img
					width={20}
					height={20}
					src="/img/blue-bullet.svg"
					alt="blue-bullet"
				/>
			)}
			{variant === 'secondary' && (
				<img
					width={20}
					height={20}
					src="/img/white-bullet.svg"
					alt="white-bullet"
				/>
			)}
			<div>{text}</div>
		</div>
	)
}

import { BulletPoint } from '#app/components/bullet-point'
import { appStoreLink, googlePlayLink } from '#app/utils/misc'

export default function BlueFooter() {
	const photo = '/img/footer.webp'
	const photosAlt =
		'objednání úklidu rychle a jednoduše přes aplikaci Izzy | paní na úklid provádí úklid apartmánů | paní na úklid provádí generální běžný úklid bytu | paní na úklid se připravuje na práci | stažení úklidové aplikace Izzy do mobilu'

	const bulletPoints = [
		'Objednání na pár kliknutí',
		'Přímá komunikace s paní na úklid v chatu',
		'Přehledný kalendář, výpisy a hodnocení',
	]

	return (
		<section className="pt-16">
			<div className="flex flex-col items-center xl:flex-none xl:items-stretch">
				<div className="relative z-10 -mb-40 block max-w-2xl px-6 xs:-mb-48 xs:px-12 sm:-mb-56 sm:px-24 md:-mb-64 md:px-0 xl:hidden">
					<img width={703} height={624} src={photo} alt={photosAlt} />
				</div>
				<div className="bg-primary px-6 xs:px-12 sm:px-24">
					<div className="mx-auto max-w-7xl px-8 py-16 xl:flex xl:items-center xl:gap-6 2xl:gap-12">
						<div className="flex flex-col gap-6 py-16 text-white xl:max-w-xl">
							<div className="mt-10 text-[40px] font-semibold leading-[60px] xs:mt-20 sm:mt-28 md:mt-36 xl:hidden">
								Stáhněte si naši aplikaci a využijte Izzy naplno
							</div>
							<div className="hidden text-[40px] font-semibold leading-[60px] xl:block">
								Jediná mobilní aplikace, přes kterou si bezpečně sjednáte
								profesionální {}
								<span>
									úklid
									<svg
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className="absolute ml-12 mt-6 inline-block"
									>
										<rect width="20" height="20" rx="10" fill="#F7658B" />
									</svg>
								</span>
							</div>

							<div className="text-[16px] leading-[26px]">
								S aplikací Izzy zvládnete objednání úklidu snadno a rychle,
								stačí pár kliknutí. Detaily vaší poptávky si s paní na úklid
								pohodlně domluvíte přímo v chatu. Aplikace vám pomůže udržovat
								si přehled o všech úklidech, fakturách a hodnoceních.
								<div className="mt-6 flex flex-col gap-4">
									{bulletPoints.map(point => (
										<BulletPoint key={point} text={point} variant="secondary" />
									))}
								</div>
							</div>
							<div className="mt-8 flex justify-center gap-3 md:justify-normal">
								<a href={appStoreLink}>
									<img
										width={135}
										height={44}
										src="/img/app-store.svg"
										alt="app-store"
									/>
								</a>
								<a href={googlePlayLink}>
									<img
										width={148.5}
										height={44}
										src="/img/google-play.svg"
										alt="google-play"
									/>
								</a>
							</div>
						</div>
						<div className="hidden xl:relative xl:block">
							<img
								width={703}
								height={624}
								src={photo}
								alt={photosAlt}
								className="relative z-10 2xl:max-w-3xl"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
